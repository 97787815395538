const parseNumberInput = (value: string): number | undefined => parseInt(value, 10) || undefined;

const parseBoolRadio = (value: string): boolean => value === 'true';

const parseIdsArray = (value?: string[]): number[] | undefined =>
    value && value.length > 0 ? value.map(Number) : undefined;

const parseIntegerUrlParam = (
    parsedUrl: URL & { params: Record<string, string[]> },
    paramName: string
): number | null => parseInt((parsedUrl.params[paramName] || [])[0], 10) || null;

export { parseNumberInput, parseBoolRadio, parseIdsArray, parseIntegerUrlParam };
