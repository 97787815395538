import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    addToPriceCartError: 'add.to.price.cart.error',
};

const AddToCartErrorNotification: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.addToPriceCartError]}</>;

export default {
    Element: translation(AddToCartErrorNotification),
    kind: 'error',
    autoClose: true,
    autoCloseDelay: 3000,
};
