import PropTypes from 'prop-types';

export const productIdType = PropTypes.string;
export const periodType = PropTypes.number;
export const regionIdType = PropTypes.string;
export const currencyType = PropTypes.string;
export const productCodeType = PropTypes.string;

export const productType = PropTypes.shape({
    code: productCodeType,
    currency: currencyType,
    // Должен быть periodType, но бэк отдаёт так
    period: PropTypes.string,
    price: PropTypes.number,
    products: PropTypes.shape({
        code: productCodeType,
        // Должен быть number, но бэк отдаёт так
        count: PropTypes.string,
    }),
    profRoleGroup: PropTypes.arrayOf(PropTypes.string),
    region: regionIdType,
});
