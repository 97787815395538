export enum VacancyAdvice {
    WorkAddress = 'ADD_WORK_ADDRESS',
    ConditionsToDescription = 'ADD_CONDITIONS_TO_DESCRIPTION',
    RequirementsToDescription = 'ADD_REQUIREMENTS_TO_DESCRIPTION',
    ResponsibilitiesToDescription = 'ADD_RESPONSIBILITIES_TO_DESCRIPTION',
    SalaryCloserToMarker = 'MAKE_SALARY_CLOSER_TO_MARKET',
    UseStandardPlus = 'USE_STANDARD_PLUS',
    PublishSalary = 'PUBLISH_SALARY',
    ChangeVacancyName = 'CHANGE_VACANCY_NAME',
    EmployerContacts = 'ADD_EMPLOYER_CONTACTS',
    UseResumeSearch = 'USE_RESUME_SEARCH',
    UseSearchTop = 'USE_SEARCH_TOP',
    DecreaseWorkExperience = 'DECREASE_WORK_EXPERIENCE',
}

// tempexp_31119_start
export enum VacancyAdviceSeverity {
    Major = 'MAJOR',
    Minor = 'MINOR',
}

export enum VacancyAdviceMetric {
    ViewCount = 'VIEW_COUNT',
    ResponseCount = 'RESPONSE_COUNT',
}

export interface VacancyAdviceDeltas {
    metric: VacancyAdviceMetric;
    before: number;
    after: number;
    deltaAbs: number;
    deltaRel: number;
}

export enum VacancyAdviceOutcome {
    ImproveVacancy = 'IMPROVE_VACANCY',
    PromoteVacancy = 'PROMOTE_VACANCY',
    IncreaseViews = 'INCREASE_VIEWS',
    AccelerateHiring = 'ACCELERATE_HIRING',
    IncreaseMetricsRel = 'INCREASE_METRICS_REL',
}

export interface VacancyAdviceWithStatistics {
    advice: VacancyAdvice;
    severity: VacancyAdviceSeverity;
    outcome: VacancyAdviceOutcome;
    deltas: VacancyAdviceDeltas[];
}
// tempexp_31119_end

export type VacancyAdviceWithAnchor = Exclude<
    VacancyAdvice,
    | VacancyAdvice.UseSearchTop
    | VacancyAdvice.UseStandardPlus
    | VacancyAdvice.UseResumeSearch
    | VacancyAdvice.ChangeVacancyName
>;
