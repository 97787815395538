import { push } from 'connected-react-router';

import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import getUpgradedEditBody from 'lux/requests/vacancyUpgrades/getUpgradedEditBody';
import getUpgradedFullVacancy from 'lux/requests/vacancyUpgrades/getUpgradedFullVacancy';

export default ({ afterVacancyTypeHasBeenChanged, isEdit, params }, addNotification) =>
    async (dispatch, getState) => {
        const { location } = getState().router;
        let response;
        try {
            response = await fetcher.postFormData('/shards/employer/vacancies/upgrade', params);
            afterVacancyTypeHasBeenChanged?.();
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
        if (location.pathname.match('/vacancy/')) {
            dispatch(push(`${location.pathname}${response.data.query}`));
            return;
        }

        if (isEdit) {
            dispatch(getUpgradedEditBody(params, addNotification));
        } else {
            dispatch(getUpgradedFullVacancy(params, addNotification));
        }
    };
