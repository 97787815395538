import { ReactElement, ReactNode, FC } from 'react';
import { Field, FieldMetaState, FieldProps, FieldRenderProps } from 'react-final-form';
import { FieldValidator } from 'final-form';

type SubscriptionType = {
    combinedProps?: boolean;
    metaSome?: boolean;
    metaAll?: boolean;
};

type FieldComponentType = <T>(props: FieldProps<T, FieldRenderProps<T>>) => ReactElement;

type FieldType = {
    initialValue?: unknown;
    name: string;
    validate?: FieldValidator<unknown>;
    validateFields?: string[];
    type?: string;
    /** Компонент, используемый в качестве Field */
    FieldComponent?: FieldComponentType;
};

type CombinedPropsType = Record<string, FieldRenderProps<unknown>>;

export interface ResultRenderProps<T> {
    combinedProps?: CombinedPropsType;
    metaSome?: FieldMetaState<T>;
    metaAll?: FieldMetaState<T>;
}

const getMetaValues = (combinedProps: CombinedPropsType, metaKey: keyof FieldMetaState<unknown>): unknown[] => {
    return Object.keys(combinedProps).reduce((acc: unknown[], fieldKey) => {
        const combinedProp = combinedProps[fieldKey];
        const { meta } = combinedProp;
        return [...acc, meta[metaKey] as unknown];
    }, []);
};

const createNextFieldSetRenderer = ({
    fields,
    currentIndex,
    subscription,
    children,
    combinedRenderProps,
    currentName,
    FieldComponent,
}: FieldSetProps & { currentIndex: number; subscription: SubscriptionType; currentName: string }) => {
    const NextFieldSet = (renderProps: FieldRenderProps<unknown>) => {
        let nextCombinedRenderProps = {};
        if (subscription.combinedProps || subscription.metaSome || subscription.metaAll) {
            nextCombinedRenderProps = { ...combinedRenderProps, [currentName]: renderProps };
        }
        return (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            <FieldSet
                fields={fields}
                currentIndex={currentIndex + 1}
                combinedRenderProps={nextCombinedRenderProps}
                subscription={subscription}
                FieldComponent={FieldComponent}
            >
                {children}
            </FieldSet>
        );
    };
    return NextFieldSet;
};

const createFinalFieldSetRenderer = ({
    subscription,
    combinedRenderProps,
    currentName,
    fields,
    children,
}: {
    subscription: SubscriptionType;
    combinedRenderProps: CombinedPropsType;
    currentName: string;
    fields: FieldType[];
    children: (resultRenderProps: ResultRenderProps<unknown>) => ReactNode;
}) => {
    const FinalField = (renderProps: FieldRenderProps<unknown>) => {
        const resultRenderProps: ResultRenderProps<unknown> = {};
        let combinedProps: CombinedPropsType = {};
        if (subscription.combinedProps || subscription.metaSome || subscription.metaAll) {
            combinedProps = { ...combinedRenderProps, [currentName]: renderProps };
        }
        if (subscription.combinedProps) {
            resultRenderProps.combinedProps = combinedProps;
        }
        if (subscription.metaSome || subscription.metaAll) {
            const combinedProp = combinedProps[fields[0].name] || { meta: {} };
            const metaKeys = Object.keys(combinedProp.meta);
            const metaSome: FieldMetaState<unknown> = {};
            const metaAll: FieldMetaState<unknown> = {};
            metaKeys.forEach((metaKey) => {
                const metaValues = getMetaValues(combinedProps, metaKey);
                if (subscription.metaSome) {
                    metaSome[metaKey] = metaValues.find((item) => item) || false;
                }
                if (subscription.metaAll) {
                    metaAll[metaKey] = metaValues.every((item) => item);
                }
            });
            if (subscription.metaSome) {
                resultRenderProps.metaSome = metaSome;
            }
            if (subscription.metaAll) {
                resultRenderProps.metaAll = metaAll;
            }
        }
        return children(resultRenderProps);
    };
    return FinalField;
};

interface FieldSetProps {
    /** Массив параметров для Field, name обязателен */
    fields: FieldType[];
    combinedRenderProps?: CombinedPropsType;
    currentIndex?: number;
    subscription?: SubscriptionType;
    FieldComponent?: FieldComponentType;
    /** Общая рендер-функция, получит { combinedProps, metaSome, metaAll } */
    children: (resultRenderProps: ResultRenderProps<unknown>) => ReactNode;
}

const FieldSet: FC<FieldSetProps> = ({
    fields = [],
    currentIndex = 0,
    children,
    combinedRenderProps = {},
    /** Какие объекты должна получить рендер-функция */
    subscription = {
        combinedProps: true,
        metaSome: true,
        metaAll: true,
    },
    /** Компонент, используемый в качестве Field */
    FieldComponent = Field,
}) => {
    if (fields.length === 0) {
        return null;
    }
    const { FieldComponent: CurrentFieldComponent = FieldComponent, ...currentField } = fields[currentIndex];
    const currentName = currentField.name;
    const currentRenderFunction =
        currentIndex < fields.length - 1
            ? // Рендерим еще один филд в филде
              createNextFieldSetRenderer({
                  fields,
                  currentIndex,
                  subscription,
                  children,
                  combinedRenderProps,
                  currentName,
                  FieldComponent,
              })
            : // Рендерим то, что отдаст общая рендер-функция
              createFinalFieldSetRenderer({ subscription, combinedRenderProps, currentName, fields, children });
    return <CurrentFieldComponent {...currentField} render={currentRenderFunction} />;
};

export default FieldSet;
