import { BrandingProductType } from 'lux/models/price/product.types';
import { ServiceCode } from 'lux/models/price/productCodes';

export enum ServiceCodesForCRM {
    BrandingPageCopywriting = 'BRAND_PAGE_COPYWRITING',
    BrandVacancyRewriting = 'BRAND_VACANCY_REWRITING',
    BrandingVacancyLogo = 'BRAND_VACANCY_LOGO',
    BrandingPageSimple = 'Z6_SIMPLE',
    BrandingPageOptimal = 'Z6_OPTIMAL',
    BrandingPageProfessional = 'Z6_PROFF',
}

export const BrandingBuilderServiceCodes = [ServiceCode.BrandingBuilderPage, ServiceCode.BrandingBuilderVacancy];

export type BrandingBuilderServiceCodeType = (typeof BrandingBuilderServiceCodes)[number];

export enum PriceItemMode {
    Article = 'ARTICLE',
    SubmitAplication = 'SUBMIT_APPLICATION',
    PeriodPrice = 'PERIOD_PRICE',
    SelectPeriodPrice = 'SELECT_PERIOD_PRICE',
    PriceVacanciesConstructorPayment = 'PRICE_VACANCIES_CONSTRUCTOR_PAYMENT',
}

export enum PriceItemImage {
    Individual = 'INDIVIDUAL',
    Typical = 'TYPICAL',
    VBC = 'VBC',
    Professional = 'PROFESSIONAL',
    Optimal = 'OPTIMAL',
    Simple = 'SIMPLE',
    Z1 = 'Z1',
    Mail = 'MAIL',
}

export interface PriceWithFormatData extends BrandingProductType {
    name: string;
    priceRegionId: string;
    priceRegionTrl: string;
    order: string;
}

export type ServiceCodesForPrice =
    | ServiceCodesForCRM
    | ServiceCode.BrandingBuilderVacancy
    | ServiceCode.BrandingBuilderPage
    | ServiceCode.BrandingMailTemplate;

export interface ServicePriceItem {
    code: ServiceCodesForPrice;
    image: PriceItemImage;
    mode: PriceItemMode;
    title: string;
    description: {
        text?: string | null;
        advantages: string[];
    };
    extra: {
        url: string;
        analyticCode: string;
        price: PriceWithFormatData[];
    };
    index: number;
}
