import { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

export enum FormVSpacingType {
    Section = 60,
    SectionTitle = 24,
    Field = 40,
    FieldItem = 12,
    TitleHint = 4,
}

interface FormVSpacingProps {
    value: FormVSpacingType;
}

const FormVSpacing: FC<FormVSpacingProps> = ({ value }) => <VSpacing default={value} />;

export default FormVSpacing;
