import { useEffect } from 'react';

import postMessage from 'Modules/PostMessage';

/**
 * usePostMessages
 * @param handlers – ключ-значение, где ключ - имя события, значение - обработчик
 */
export default (handlers = {}) => {
    useEffect(() => {
        return postMessage.on({
            handlers,
        });
    }, [handlers]);
};
