import Cookies from 'bloko/common/Cookies';
import urlParser from 'bloko/common/urlParser';

const generateInput = function (name: string, value: string | number | null): HTMLInputElement {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);

    if (value || value === 0) {
        input.setAttribute('value', value.toString());
    }

    return input;
};

declare global {
    /**
     * Интерфейсы описания запросов/ответов для SubmitPostData
     * Можно дополнять с помощью declaration merging по месту использования
     *
     * declare global {
     *      interface SubmitPostDataApi {
     *          'some/url': { ... }
     *      }
     * }
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SubmitPostDataApi {
        // TODO: удалить, как только будет написано одно определение SubmitPostDataApi
        someUrl: {
            example: never;
        };
    }
}

interface SubmitPostData {
    <U extends keyof SubmitPostDataApi>(data: {
        url: U;
        data: SubmitPostDataApi[U] & Record<string, string | string[]>;
        noXsrf?: boolean;
        target?: string;
        isIframe?: boolean;
    }): void;
}

const submitPostData: SubmitPostData = ({ url, data, noXsrf, target, isIframe }) => {
    const fakeForm = document.createElement('form');
    fakeForm.setAttribute('method', 'post');
    const parsedUrl = urlParser(String(url));
    parsedUrl.params.hhtmFrom = parsedUrl.params.hhtmFrom || window.globalVars.analyticsParams.hhtmSource;
    fakeForm.setAttribute('action', parsedUrl.href);
    fakeForm.setAttribute('style', 'position: absolute; left: -9999px; top: -9999px');
    if (target) {
        fakeForm.setAttribute('target', target);
    }
    Object.keys(data).forEach((name) => {
        const dataField = data[name];
        if (Array.isArray(dataField)) {
            dataField.forEach((item) => {
                fakeForm.appendChild(generateInput(name, item));
            });
        } else {
            fakeForm.appendChild(generateInput(name, dataField));
        }
    });
    if (!noXsrf) {
        fakeForm.appendChild(generateInput('_xsrf', Cookies.get('_xsrf')));
    }
    if (isIframe) {
        fakeForm.setAttribute('target', '_parent');
    }
    document.body.appendChild(fakeForm);
    fakeForm.submit();
    fakeForm.remove();
};

export default submitPostData;
