const VACANCY_ACTIONS = {
    CREATE: 'CREATE',
    CREATE_FROM_DRAFT: 'CREATE_FROM_DRAFT',
    CREATE_FROM_TEMPLATE: 'CREATE_FROM_TEMPLATE',
    RESTORE: 'RESTORE',
    DUPLICATE: 'DUPLICATE',
    EDIT: 'EDIT',
    EDIT_PENDING_DRAFT: 'EDIT_PENDING_DRAFT',
} as const;

export type VacancyAction = (typeof VACANCY_ACTIONS)[keyof typeof VACANCY_ACTIONS];

export default VACANCY_ACTIONS;
