import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    save: 'auction.modal.title.notification.save',
};

const VacancyAuctionSaveSuccessfullyModalNotification: TranslatedComponent = ({ trls }) => (
    <span>{trls[TrlKeys.save]}</span>
);

export default {
    Element: translation(VacancyAuctionSaveSuccessfullyModalNotification),
    kind: NotificationKind.Ok,
    autoClose: true,
};
