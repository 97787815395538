import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfMonth from 'date-fns/endOfMonth';
import isBefore from 'date-fns/isBefore';
import min from 'date-fns/min';
import startOfMonth from 'date-fns/startOfMonth';
import subDays from 'date-fns/subDays';

import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    DatePicker,
    DateType,
    Drop,
    Link,
    NavigationBar,
    useBreakpoint,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { addDays, toISO } from 'bloko/blocks/calendar/datesHelper';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { WINDOW_LOCALE } from 'Modules/formatDate';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';

import StartDateSelect from 'lux/components/VacancyCreate/sections/MainInfo/HiringPlanFields/StartDate/StartDateSelect';

const TrlKeys = {
    selectPrefix: 'hiringPlan.startDate.selectPrefix',
    selectDefaultPlaceholder: 'hiringPlan.startDate.selectDefaultPlaceholder',
    startDate: 'hiringPlan.startDate.button.startDate',
    noDate: 'hiringPlan.startDate.button.noDate',
    expiredError: 'hiringPlan.startDate.expiredError',
    datePickerPlaceholder: 'hiringPlan.startDate.placeholder',
    applyDate: 'hiringPlan.startDate.applyDate',
};

const dateFormat = 'yyyy-MM-dd';
const mskTimezone = '+03:00';

interface StartDateProps {
    shouldProlongateNoDate: boolean;
    setShouldProlongateNoDate: (val: boolean) => void;
    withoutDate: boolean;
}

const validateDate = (value: string) =>
    !!value && isBefore(new Date(value), new Date(formatInTimeZone(new Date(), mskTimezone, dateFormat)));

const dateTypeToDate = (value: Date | number | string) => (value instanceof Date ? value : new Date(value));

const calcDisabledDatesBeforeDate = (date: DateType) => {
    const dateValue = dateTypeToDate(date);

    const yesterday = subDays(Date.now(), 1);
    const interval = {
        start: min([startOfMonth(dateValue), yesterday]),
        end: min([endOfMonth(dateValue), yesterday]),
    };
    return eachDayOfInterval(interval);
};

const StartDate: TranslatedComponent<FieldRenderProps<string>['input'] & StartDateProps> = ({
    trls,
    value,
    shouldProlongateNoDate,
    setShouldProlongateNoDate,
    withoutDate,
    onChange,
}) => {
    const { isMobile } = useBreakpoint();
    const [selectedDate, setSelectedDate] = useState<Date | null>(value ? new Date(value) : null);
    const [isExpiredError, setIsExpiredError] = useState(validateDate(value));
    const [shouldShow, show, hide] = useOnOffState(false);
    const [displayDate, setDisplayDate] = useState<DateType>(selectedDate || new Date());
    const datePickerButton = useRef<HTMLElement>(null);

    const changeDate = useCallback(
        (value: DateType | null) => {
            if (value) {
                const dateValue = dateTypeToDate(value);
                onChange(toISO(dateValue));
                setSelectedDate(dateValue);
                return;
            }

            onChange('');
            setSelectedDate(null);
        },
        [onChange]
    );

    const handleProlongateDate = useCallback(() => {
        const newDate = addDays(new Date(), 30);
        changeDate(newDate);
        setIsExpiredError(false);
    }, [changeDate]);

    const changeDateAndClose = useCallback(
        (date: DateType | null) => {
            changeDate(date);
            hide();
        },
        [changeDate, hide]
    );

    const selectDate = useCallback((date: DateType) => {
        const dateValue = dateTypeToDate(date);
        setSelectedDate(dateValue);
    }, []);

    useEffect(() => {
        if (!selectedDate && shouldProlongateNoDate) {
            setTimeout(() => {
                // Этот эффект запускается в момент маунта и FinalForm не успевает подписаться на изменения поля.
                // Значение даты проставляется, но событие о том что дата обновилась не прилетает.
                // setTimeout решает эту проблему
                handleProlongateDate();
            });
            setShouldProlongateNoDate(false);
        }
    }, [handleProlongateDate, shouldProlongateNoDate, selectedDate, setShouldProlongateNoDate]);

    useEffect(() => {
        if (value) {
            setDisplayDate(dateTypeToDate(value));
        }
    }, [value]);

    useEffect(() => {
        if (withoutDate) {
            changeDateAndClose(null);
        }
    }, [changeDateAndClose, withoutDate, hide]);

    const datePicker = (
        <DatePicker
            enabledCalendars="dd.mm.yyyy"
            selectedDate={selectedDate}
            onDateSelect={isMobile ? selectDate : changeDateAndClose}
            disabledDates={calcDisabledDatesBeforeDate(displayDate)}
            displayDate={displayDate}
            onDisplayDateChange={setDisplayDate}
            locale={WINDOW_LOCALE}
        />
    );
    return (
        <>
            <StartDateSelect
                ref={datePickerButton}
                dateValue={value}
                isExpiredError={isExpiredError}
                onClick={show}
                disabled={withoutDate}
            />
            <Drop
                visible={shouldShow}
                activatorRef={datePickerButton}
                alignment="left"
                direction="bottom"
                maxWidth={410}
                space={400}
                onClose={hide}
            >
                {datePicker}
            </Drop>
            <BottomSheet
                visible={shouldShow}
                header={<NavigationBar title={trls[TrlKeys.datePickerPlaceholder]} showDivider="always" />}
                footer={
                    <BottomSheetFooter>
                        <Button
                            mode="primary"
                            stretched
                            onClick={() => {
                                changeDateAndClose(selectedDate);
                            }}
                        >
                            {trls[TrlKeys.applyDate]}
                        </Button>
                    </BottomSheetFooter>
                }
                onClose={hide}
            >
                {datePicker}
            </BottomSheet>
            {isExpiredError && (
                <>
                    <VSpacing default={8} />
                    <Link Element="button" onClick={handleProlongateDate}>
                        {trls[TrlKeys.expiredError]}
                    </Link>
                </>
            )}
        </>
    );
};

export default translation(StartDate);
