import { FieldRenderProps } from 'react-final-form';

import { Select } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    title: 'employer.vacancy.VacancyCreateContent.mainInfoSection.hiringPlan.title',
    newHire: 'hiringPlan.type.newHire',
    replacement: 'hiringPlan.type.replacement',
};

const NEW_HIRE = 'NEW_HIRE';
const REPLACEMENT = 'REPLACEMENT';

interface PlanTypeProps {
    redesigned?: boolean;
}

const PlanType: TranslatedComponent<FieldRenderProps<string>['input'] & PlanTypeProps> = ({
    trls,
    value,
    onChange,
    name,
}) => {
    const hiringPlanTypes = [
        { label: trls[TrlKeys.newHire], value: NEW_HIRE },
        { label: trls[TrlKeys.replacement], value: REPLACEMENT },
    ];
    return (
        <Select
            data-qa="hiring-plan-type"
            name={name}
            value={value}
            onChange={onChange}
            options={hiringPlanTypes}
            placeholder={trls[TrlKeys.title]}
            bottomSheetHeight="content"
        />
    );
};

export default translation(PlanType);
