import { ComponentProps, FC, ReactElement, ReactNode, isValidElement } from 'react';

import { Checkbox, Cell, CellText, useBreakpoint } from '@hh.ru/magritte-ui';

import styles from './checkbox-with-label.less';

const getCellTitle = (title: string | ReactElement) => {
    if (isValidElement(title)) {
        return title;
    }
    return <CellText type="title">{title}</CellText>;
};

type MagritteCheckboxProps = ComponentProps<typeof Checkbox>;

interface CheckboxWithLabelProps extends Omit<MagritteCheckboxProps, 'title'> {
    disabled?: boolean;
    title?: string | ReactElement;
    subtitle?: ReactNode;
    description?: ReactNode;
    right?: ReactElement;
    bottom?: ReactElement;
}

const CheckboxWithLabel: FC<CheckboxWithLabelProps> = ({
    disabled,
    title,
    subtitle,
    description,
    right,
    bottom,
    'data-qa': dataQaCheckbox,
    ...checkboxProps
}) => {
    const { isXS } = useBreakpoint();

    return (
        <Cell align="top" disabled={disabled} left={<Checkbox {...checkboxProps} />} right={!isXS ? right : undefined}>
            {title && getCellTitle(title)}
            {subtitle && <CellText type="subtitle">{subtitle}</CellText>}
            {description && <CellText type="description">{description}</CellText>}
            {bottom}
            {right && isXS && <div className={styles.rightCell}>{right}</div>}
        </Cell>
    );
};

export default CheckboxWithLabel;
