import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Modal, { ModalHeader, ModalTitle, ModalContent, ModalFooter } from 'bloko/blocks/modal';

import translation from 'lux/components/translation';

import UpgradeVariants from 'lux/components/VacancyModals/UpgradeModal/UpgradeVariants';

const UpgradeModal = ({
    afterVacancyTypeHasBeenChanged,
    handleCloseModal,
    vacancyId,
    employerId,
    isVisible,
    isEdit,
    trls,
}) => (
    <Modal visible={isVisible} onClose={handleCloseModal}>
        <Fragment>
            <ModalHeader outlined>
                <ModalTitle>{trls[UpgradeModal.trls.upgradeTitle]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className="vacancy-upgrade-popup-content" data-qa="vacancy-upgrade-menu">
                    <p>{trls[UpgradeModal.trls.info]}</p>
                    <div className="vacancy-upgrade__variants">
                        <UpgradeVariants
                            afterVacancyTypeHasBeenChanged={afterVacancyTypeHasBeenChanged}
                            isEdit={isEdit}
                            vacancyId={vacancyId}
                            employerId={employerId}
                            handleClose={handleCloseModal}
                        />
                    </div>
                </div>
                <Gap top>
                    <div className="vacancy-upgrade-warning">{trls[UpgradeModal.trls.autoUpdateWarning]}</div>
                </Gap>
            </ModalContent>
            <ModalFooter>
                <Button onClick={handleCloseModal}>{trls[UpgradeModal.trls.cancel]}</Button>
            </ModalFooter>
        </Fragment>
    </Modal>
);

UpgradeModal.propTypes = {
    afterVacancyTypeHasBeenChanged: PropTypes.func,
    isEdit: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    vacancyUpgrades: PropTypes.object,
    vacancyId: PropTypes.number,
    employerId: PropTypes.number,
    isVisible: PropTypes.bool,
    trls: PropTypes.object,
};

UpgradeModal.trls = {
    info: 'vacancy.update.text',
    upgradeTitle: 'vacancy.upgrade.popup.title',
    cancel: 'employer.myVacancyes.move.cancel',
    autoUpdateWarning: 'vacancy.autoUpdate.notCancel',
};

export default translation(UpgradeModal);
