import { WorkSchedule, Employment, WorkExperience } from '@hh.ru/types-hh-microcore';

import { BrandedTemplate } from 'lux/models/brandedVacancyTemplates';
import { CurrencyType } from 'lux/models/currencies.types';
import DriverLicense from 'lux/models/driverLicense.types';
import { EmployerAddress } from 'lux/models/employerAddresses.types';
import { EmployerDepartment } from 'lux/models/employerDepartments';
import { EmployerInsider } from 'lux/models/employerInsiders';
import { HiringPlanType } from 'lux/models/employerVacancies/vacancyHiringPlans';
import ContactInfo from 'lux/models/vacancy/contactInfo.types';
import { PublicationVacancyProperties } from 'lux/models/vacancyProperties';

import { AvailablePublicationType } from 'lux/models/vacancyCreate/availablePublicationTypes';

interface EmployerTest {
    id: string;
    name: string;
}

export interface Address {
    addressId: number;
    showMetroOnly: boolean;
}

export interface VacancyTest {
    userTestId: number;
}

export interface Publication {
    auction?: {
        bid: number;
        budget: number;
        checked: boolean;
    };
    clickmeProducts: {
        autoCampaignChecked: boolean;
    };
    hiddenFromSearch: boolean;
    employerServiceId: string;
    withZpCrossPost?: boolean;
    advertising?: boolean;
    anonymous?: boolean;
    publicationVacancyProperties: PublicationVacancyProperties;
}

export enum ChatWritePossibility {
    Disabled = 'DISABLED',
    EnabledAfterInvitation = 'ENABLED_AFTER_INVITATION',
}

export interface IVacancyCreateFormValues {
    acceptHandicapped: boolean;
    acceptIncompleteResumes: boolean;
    acceptTemporary: boolean;
    acceptKids: boolean;
    address?: Address;
    vacancyName: string;
    professionalRoleIds: string[];
    areaIds: number[];
    hiringPlan?: HiringPlanType;
    compensation: {
        currencyCode: CurrencyType;
        compensationGross: boolean;
    };
    contactInfo: ContactInfo;
    departmentCode: string;
    driverLicenseTypes: DriverLicense[];
    description: string;
    brandedTemplate: string | null;
    employment: Employment;
    keySkills: string[];
    responseLetterRequired: boolean;
    workExperience: WorkExperience;
    workSchedule: WorkSchedule;
    chatWritePossibility: ChatWritePossibility;
    employerManagerId: string;
    responseNotifications: boolean;
    publicationTime: string;
    scheduledPublicationDateTimeIso: string;
    workingDays: [];
    workingTimeModes: [];
    workingTimeIntervals: [];
    publication: Publication;
    test?: VacancyTest;
    vacancyId?: number;
    skillsSurveyProfession?: string | null;
    showSkillsSurvey?: boolean | null;
    assignedEmployerManagerId?: string | null;
    timeStart: string;
    timeEnd: string;
}

export type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export type VacancyCreateFormValues = Nullable<IVacancyCreateFormValues>;

export interface AdditionFormStateValues {
    employerInsiders: EmployerInsider[];
    employerAddresses: EmployerAddress[];
    employerDepartments: EmployerDepartment[];
    employerTests: EmployerTest[];
    brandedVacancyTemplates: BrandedTemplate[];
    selectedPublicationTypeData: AvailablePublicationType | null;
    vacancyCreateInitialBody: Partial<VacancyCreateFormValues>;
}

export type VacancyCreateTemplateId = number | null;

export interface VacancyCreateTemplate {
    id: VacancyCreateTemplateId;
    name: string;
    shared: boolean;
}
