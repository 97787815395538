import { forwardRef, Ref } from 'react';
import classnames from 'classnames';

import { Button as MagritteButton, Text } from '@hh.ru/magritte-ui';
import { CalendarOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HumanDate, { ShowYear } from 'lux/components/HumanDate';
import translation from 'lux/components/translation';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';

import styles from './styles.less';

const TrlKeys = {
    selectPrefix: 'hiringPlan.startDate.selectPrefix',
    selectDefaultPlaceholder: 'hiringPlan.startDate.selectDefaultPlaceholder',
    expiredError: 'hiringPlan.startDate.expiredError',
};

interface StartDateSelectProps {
    isExpiredError?: boolean;
    fieldRef?: HTMLDivElement;
    dateValue?: string;
    onClick: () => void;
    disabled: boolean;
    fwdRef?: Ref<HTMLElement>;
}

const StartDateSelect: TranslatedComponent<StartDateSelectProps> = ({
    trls,
    dateValue,
    isExpiredError,
    disabled,
    onClick,
    fwdRef,
}) => {
    const startDateSelectText = dateValue ? (
        <div className={classnames({ [styles.dateError]: isExpiredError })}>
            {trls[TrlKeys.selectPrefix]}
            {NON_BREAKING_SPACE}
            <HumanDate showYear={ShowYear.NotCurrent} date={dateValue} />
        </div>
    ) : (
        trls[TrlKeys.selectDefaultPlaceholder]
    );

    return (
        <MagritteButton
            ref={fwdRef}
            stretched
            mode="secondary"
            icon={<CalendarOutlinedSize24 />}
            data-qa="hiring-plan-start-date"
            onClick={onClick}
            disabled={disabled}
        >
            <Text typography="label-2-regular">{startDateSelectText}</Text>
        </MagritteButton>
    );
};
const Translated = translation(StartDateSelect);
export default forwardRef<HTMLElement, StartDateSelectProps>((props, ref) => <Translated {...props} fwdRef={ref} />);
