import { FC, ReactNode, useEffect, useState } from 'react';

import elementSpy, { Bound } from 'bloko/common/elementSpy';

import { useExperimentGroupCheckFunction } from 'lux/hooks/useExperimentCheckFunction';

interface Props {
    Element?: 'div' | 'span' | 'p';
    children: ReactNode;
    experimentName: string;
    trackingBounds?: Bound[];
    // вызывается каждый раз когда элемент находится во вьюпорт
    onShow?: () => void;
}

const ExperimentCheckOnElementShown: FC<Props> = ({
    Element = 'div',
    onShow,
    children,
    trackingBounds = [Bound.Top],
    experimentName,
}) => {
    const [element, setElement] = useState<HTMLElement | null>(null);

    const getExperimentGroup = useExperimentGroupCheckFunction();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (element) {
            const { stopSpying } = elementSpy(element, {
                onShow() {
                    getExperimentGroup(experimentName);
                    if (onShow) {
                        onShow();
                    }
                },
                trackingBounds,
            });
            return () => {
                stopSpying();
            };
        }
    }, [element, experimentName, getExperimentGroup, onShow, trackingBounds]);

    return <Element ref={setElement}>{children}</Element>;
};

export default ExperimentCheckOnElementShown;
