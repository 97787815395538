import { FieldMetaState } from 'react-final-form';
import { shallowEqual } from 'react-redux';
import { getIn, setIn } from 'final-form';

import { isObject } from 'Utils/Utils';
import { EmployerManager, ManagerPhone } from 'lux/models/employerManagersList';
import ContactInfo from 'lux/models/vacancy/contactInfo.types';
import { AvailablePublicationType } from 'lux/models/vacancyCreate/availablePublicationTypes';
import {
    AdditionFormStateValues,
    Address,
    ChatWritePossibility,
    Nullable,
    VacancyCreateFormValues,
    VacancyTest,
    IVacancyCreateFormValues,
    Publication,
} from 'lux/models/vacancyCreate/vacancyCreate.types';

export const VACANCY_CREATE_GET_URL = '/employer/vacancy/create';
export const EDIT_PENDING_DRAFT_GET_URL = '/employer/vacancy/edit_pending';

const isFilledPhone = (phone: ManagerPhone | null): phone is ManagerPhone =>
    !!phone && Object.values(phone).some(Boolean);
const BACKOFFICE_EDIT_PROHIBITED_FIELDS = ['publication'];

const isContactInfo = (value: unknown): value is ContactInfo =>
    (value as ContactInfo).phones !== undefined && (value as ContactInfo).fio !== undefined;

const isAddress = (value: unknown): value is Address => (value as Address).addressId !== undefined;

const isVacancyTest = (value: unknown): value is VacancyTest => (value as VacancyTest).userTestId !== undefined;

const isPublication = (value: unknown): value is Publication =>
    (value as Publication).publicationVacancyProperties !== undefined;

export const extractContactInfo = (employerManagersList: EmployerManager[], managerId: string): ContactInfo | null => {
    const managerData = employerManagersList.find((manager) => manager.id === managerId);

    if (!managerData) {
        return null;
    }

    return {
        fio: managerData.text,
        email: managerData.email,
        phones: [managerData.phone, managerData.additionalPhone].filter(isFilledPhone),
    };
};

const isKeyOfObject = <T>(key: unknown, object: T): key is keyof T => Object.keys(object).includes(key);

const filterValues = (
    values: VacancyCreateFormValues,
    selectedPublicationTypeData: AvailablePublicationType | null,
    isDelete: boolean
): Partial<VacancyCreateFormValues> => {
    const {
        additionalFields = [],
        ignoredFields = [],
        removedAdditionalFields = [],
    } = selectedPublicationTypeData || {};

    const withoutFields = [...ignoredFields, ...removedAdditionalFields].filter(
        (field) => !additionalFields.includes(field)
    );
    const filteredValues: Partial<VacancyCreateFormValues> = { ...values };

    const publicationVariant = values.publication && values.publication?.publicationVacancyProperties?.id;
    if (!publicationVariant) {
        delete filteredValues.publication;
    }

    const removeValue = isDelete
        ? (key: string) => {
              if (isKeyOfObject(key, filteredValues)) {
                  delete filteredValues[key];
              }
          }
        : (key: string) => {
              if (isKeyOfObject(key, filteredValues)) {
                  filteredValues[key] = null;
              }
          };

    withoutFields.forEach(removeValue);
    return filteredValues;
};

const formatters = {
    contactInfo: (contacts: unknown) => {
        if (!contacts) {
            return contacts;
        }
        return isContactInfo(contacts)
            ? {
                  ...contacts,
                  phones: contacts.phones.filter(isFilledPhone),
                  email: contacts.email ? contacts.email.toLowerCase() : null,
              }
            : null;
    },
    insiderId: (id: unknown, { employerInsiders }: AdditionFormStateValues) =>
        employerInsiders.some(({ code }) => String(code) === String(id)) ? id : null,
    address: (value: unknown, { employerAddresses }: AdditionFormStateValues) =>
        value && isAddress(value) && employerAddresses.some((address) => String(address.id) === String(value.addressId))
            ? value
            : null,
    departmentCode: (value: unknown, { employerDepartments }: AdditionFormStateValues) =>
        employerDepartments.some(({ code }) => String(code) === String(value)) ? value : null,
    brandedTemplate: (value: unknown, { brandedVacancyTemplates }: AdditionFormStateValues) =>
        brandedVacancyTemplates.some(({ name }) => String(name) === String(value)) ? value : null,
    test: (value: unknown, { employerTests }: AdditionFormStateValues) =>
        isVacancyTest(value) && employerTests.some(({ id }) => String(id) === String(value?.userTestId)) ? value : null,
    publication: (value: unknown, { selectedPublicationTypeData }: AdditionFormStateValues) => {
        if (isPublication(value)) {
            if (selectedPublicationTypeData?.vacancyProperties) {
                return {
                    ...value,
                    publicationVacancyProperties: selectedPublicationTypeData.vacancyProperties,
                };
            }

            if (value.publicationVacancyProperties?.properties) {
                return value;
            }
        }

        return null;
    },
};

export const prepareValue = (
    field: keyof typeof formatters | string,
    value: unknown,
    additionalStateValues: AdditionFormStateValues
): unknown => {
    if (isKeyOfObject(field, formatters)) {
        return formatters[field](value, additionalStateValues);
    }
    if (typeof value === 'string') {
        return value.trim();
    }
    return value;
};

export const getMissingValueWithDeleteMark = (oldValue: unknown, newValue: unknown, defaultValue = null): unknown => {
    if (newValue === undefined) {
        return defaultValue;
    }

    if (!newValue || !isObject(oldValue) || !isObject(newValue)) {
        return newValue;
    }

    const resultValue = { ...newValue };
    Object.keys(oldValue).forEach((oldSubfield) => {
        resultValue[oldSubfield] = isKeyOfObject(oldSubfield, resultValue)
            ? getMissingValueWithDeleteMark(
                  oldValue[oldSubfield],
                  resultValue[oldSubfield],
                  defaultValue?.[oldSubfield]
              )
            : null;
    });

    return resultValue;
};

const emptyValues = ['', undefined, null];
export const filterEmptyValues = <T>(values: T, isDelete = false): Nullable<T> => {
    const filteredValues: Nullable<T> = { ...values };

    for (const field in filteredValues) {
        if (isKeyOfObject(field, filteredValues)) {
            const value = filteredValues[field];
            if (emptyValues.includes(value)) {
                if (isDelete) {
                    filteredValues[field] = null;
                } else {
                    delete filteredValues[field];
                }
            } else if (isObject(value)) {
                filteredValues[field] = filterEmptyValues(value) as never;
            }
        }
    }
    return filteredValues;
};

export const prepareValues = (
    values: VacancyCreateFormValues,
    additionalStateValues: AdditionFormStateValues,
    isDelete: boolean,
    isEdit: boolean,
    isBackoffice: boolean
): Partial<VacancyCreateFormValues> => {
    const isBackofficeEdit = isEdit && isBackoffice;
    const valuesFilteredByTypeFields = filterValues(
        values,
        additionalStateValues.selectedPublicationTypeData,
        isDelete
    );

    const valuesFilteredByEmptyFields = filterEmptyValues(valuesFilteredByTypeFields, isDelete);

    Object.keys(valuesFilteredByEmptyFields).forEach((field) => {
        valuesFilteredByEmptyFields[field] = prepareValue(
            field,
            valuesFilteredByEmptyFields[field],
            additionalStateValues
        ) as never;
    });

    if (isEdit) {
        Object.keys(additionalStateValues.vacancyCreateInitialBody).forEach((fieldName) => {
            valuesFilteredByEmptyFields[fieldName] = getMissingValueWithDeleteMark(
                additionalStateValues.vacancyCreateInitialBody[fieldName],
                valuesFilteredByEmptyFields[fieldName]
            ) as never;
        });
    }

    if (isBackofficeEdit) {
        return Object.fromEntries(
            Object.entries(valuesFilteredByEmptyFields).filter(
                ([fieldName]) => !BACKOFFICE_EDIT_PROHIBITED_FIELDS.includes(fieldName)
            )
        );
    }

    if (!isBackofficeEdit && !Object.keys(values).includes('chatWritePossibility')) {
        valuesFilteredByEmptyFields.chatWritePossibility = ChatWritePossibility.EnabledAfterInvitation;
    }
    return valuesFilteredByEmptyFields;
};

const hasErrors = (submitError: { errors?: string[] }): submitError is { errors: string[] } => !!submitError?.errors;

export const shouldShowError = ({
    active,
    touched,
    error,
    submitError,
    dirtySinceLastSubmit,
}: FieldMetaState<unknown>): boolean => {
    const hasError = !!error && typeof error !== 'object';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const hasSubmitError = !dirtySinceLastSubmit && hasErrors(submitError);
    return !active && ((touched && hasError) || hasSubmitError);
};

export const formatRemoteValidationErrors = (errors: Record<string, unknown>): Record<string, unknown> => {
    const phone = getIn(errors, 'contactInfo.phones.phone') as string[];
    const addressErrors = getIn(errors, 'address.errors') as string[];
    const publicationTimeErrors = getIn(errors, 'scheduledPublicationDateTimeIso') as string[];

    if (phone) {
        const phones = [phone[0], phone[1]].filter(Boolean);
        return setIn(errors, 'contactInfo.phones', phones) as Record<string, unknown>;
    } else if (addressErrors) {
        return setIn(errors, 'address.addressId.errors', addressErrors) as Record<string, unknown>;
    } else if (publicationTimeErrors) {
        return setIn(errors, 'scheduledPublicationDateTimeIso', publicationTimeErrors) as Record<string, unknown>;
    }

    return errors;
};

export const formatClickmeValidationErrors = (errors: Record<string, unknown>): Record<string, unknown> => {
    return {
        publication: {
            clickmeAutoCampaign: {
                ...errors,
            },
        },
    };
};

type Field = keyof VacancyCreateFormValues;
type IField = keyof IVacancyCreateFormValues;

interface ChangeType<F extends IField> {
    (oldField: IVacancyCreateFormValues[F], newField: IVacancyCreateFormValues[F]): boolean;
}

type CompareFieldsScheme = {
    [K in IField]?: ChangeType<K>;
};

const compareFieldsScheme: CompareFieldsScheme = {
    publication: (oldField, newField) =>
        oldField.publicationVacancyProperties.id === newField.publicationVacancyProperties.id &&
        Boolean(oldField.hiddenFromSearch) === Boolean(newField.hiddenFromSearch) &&
        oldField.employerServiceId === newField.employerServiceId &&
        oldField.withZpCrossPost === newField.withZpCrossPost &&
        oldField.auction?.checked === newField.auction?.checked &&
        oldField.auction?.bid === newField.auction?.bid &&
        oldField.auction?.budget === newField.auction?.budget &&
        oldField.clickmeProducts?.autoCampaignChecked === newField.clickmeProducts?.autoCampaignChecked,
    contactInfo: (oldField, newField) => {
        const { phones: oldPhones, ...oldValues } = oldField;
        const { phones: newPhones, ...newValues } = newField;
        if (!shallowEqual(oldPhones, newPhones)) {
            return false;
        }
        return shallowEqual(oldValues, newValues);
    },
    description: (oldField, newField) => newField.replace(/\r?\n|\s+/g, '') === oldField.replace(/\r?\n|\s+/g, ''),
};

export const compareField = <F extends Field>(
    oldValue: VacancyCreateFormValues[F],
    newValue: VacancyCreateFormValues[F],
    field: F
): boolean => {
    if (!oldValue || !newValue) {
        return oldValue === newValue;
    }

    return (compareFieldsScheme[field] || shallowEqual)(oldValue, newValue);
};

export default {
    extractContactInfo,
    prepareValue,
    prepareValues,
    shouldShowError,
    formatRemoteValidationErrors,
    compareField,
};
