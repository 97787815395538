import { vacancyUpgradeSuccess } from 'lux/components/Notifications/EmployerVacancies';
import { upgradeManagerVacancy } from 'lux/models/employerVacancies/managerVacancies';
import { clearUpgradeInfo } from 'lux/models/employerVacancies/vacancyUpgrades';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

export default (params, addNotification) => async (dispatch) => {
    let vacancy;
    try {
        vacancy = (await fetcher.get('/shards/employer/vacancies/get_full_vacancy', { params })).vacancy;
    } catch (error) {
        defaultRequestErrorHandler(error, addNotification);
    }
    dispatch([clearUpgradeInfo(params.vacancyId), upgradeManagerVacancy({ vacancy })]);

    addNotification(vacancyUpgradeSuccess, {
        props: {
            name: vacancy.name,
            metallic: vacancy.metallic,
        },
    });
};
