import memoize from 'memoize-one';

export interface Rules {
    [index: string]: Rules | number | boolean | string | undefined;
    fields?: Rules;
}

export const hasRequiredRule = (fieldRules: Rules): boolean =>
    Object.keys(fieldRules).some((rule) => rule === 'required' && fieldRules[rule]);

const getSubRules = ({ fields = {} }: Rules, namePart: string): Rules => {
    if (isNaN(Number(namePart))) {
        return <Rules>fields?.[namePart] || {};
    }

    return <Rules>Object.values(fields)[0] || {};
};

/**
 * Извлекает правила валидации конкретного поля из объекта всех правил валидации по имени поля.
 *
 * @param {String} name имя поля
 * @param {Object} rules объект со всеми правилами валидации
 *
 * @returns {Object} подобъект из rules, содержащий правила валидации поля
 */
export default memoize((name: string, rules: Rules): Rules => {
    const nameParts = name.split('.');
    let currentRule: Rules = { fields: rules };

    for (const namePart of nameParts) {
        currentRule = getSubRules(currentRule, namePart);
    }

    return currentRule;
});
