import { FieldRenderProps, FieldMetaState } from 'react-final-form';

import { Button as MagritteButton, NumberInput } from '@hh.ru/magritte-ui';
import { MinusOutlinedSize24, PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { shouldShowError } from 'lux/components/VacancyCreate/utils';
import translation from 'lux/components/translation';

import styles from './styles.less';

const TrlKeys = {
    plannedQuantity: 'hiringPlan.plannedQuantity.placeholder',
    plannedQuantityDecrease: 'hiringPlan.plannedQuantity.decrease',
    plannedQuantityIncrease: 'hiringPlan.plannedQuantity.increase',
    plannedQuantityCountOne: 'hiringPlan.plannedQuantity.count.one',
    plannedQuantityCountSome: 'hiringPlan.plannedQuantity.count.some',
    plannedQuantityCountMany: 'hiringPlan.plannedQuantity.count.many',
    notNumberError: 'employer.vacancy.VacancyCreateContent.mainInfoSection.compensation.notNumberError',
};

interface PlannedCountInputProps {
    onRemoveHiringPlan: () => void;
    maxValue: number;
    minValue: number;
    meta: FieldMetaState<number | undefined>;
    redesigned?: boolean;
}

const PlannedCountInput: TranslatedComponent<FieldRenderProps<string>['input'] & PlannedCountInputProps> = ({
    trls,
    value,
    onChange,
    meta,
    maxValue,
    minValue,
    onRemoveHiringPlan,
}) => {
    const numberedValue = Number(value);

    const handleChange = (val: string | number) => {
        if (!val) {
            onRemoveHiringPlan();
            return;
        }
        const plannedHiringNumber = Number(val);
        if (plannedHiringNumber >= minValue && plannedHiringNumber <= maxValue) {
            onChange(plannedHiringNumber);
        }
    };

    const plannedQuantityIncrement = () => {
        if (numberedValue < maxValue) {
            onChange(numberedValue + 1);
        }
    };

    const plannedQuantityDecrement = () => {
        if (numberedValue >= minValue) {
            handleChange(numberedValue - 1);
        }
    };

    return (
        <div className={styles.hiringPlanNumberWrapper}>
            <NumberInput
                data-qa="hiring-plan-count-input"
                placeholder={trls[TrlKeys.plannedQuantity]}
                groupSize={3}
                decimalLength={0}
                invalid={shouldShowError(meta)}
                allowNegative={false}
                clearable
                onChange={handleChange}
                value={`${value}`}
                postfix={
                    <Conversion
                        hasValue={false}
                        value={numberedValue}
                        one={trls[TrlKeys.plannedQuantityCountOne]}
                        some={trls[TrlKeys.plannedQuantityCountSome]}
                        many={trls[TrlKeys.plannedQuantityCountMany]}
                    />
                }
            />
            <MagritteButton
                data-qa="hiring-plan-count-minus"
                mode="secondary"
                aria-label={trls[TrlKeys.plannedQuantityDecrease]}
                hideLabel
                icon={<MinusOutlinedSize24 />}
                disabled={numberedValue === minValue - 1}
                onClick={plannedQuantityDecrement}
            />
            <MagritteButton
                data-qa="hiring-plan-count-plus"
                mode="secondary"
                aria-label={trls[TrlKeys.plannedQuantityIncrease]}
                hideLabel
                icon={<PlusOutlinedSize24 />}
                disabled={numberedValue === maxValue}
                onClick={plannedQuantityIncrement}
            />
        </div>
    );
};

export default translation(PlannedCountInput);
