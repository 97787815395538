import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { vacancyUpdateInitialBodyAction } from 'lux/models/vacancyCreate/vacancyCreateInitialBody';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const vacancyFeaturesAction = makeSetStoreField('vacancyFeatures');

export default (params, addNotification) => async (dispatch, getState) => {
    const { employerManagerId } = getState().vacancyCreateDefaultBody;
    let upgradedInfo;
    try {
        upgradedInfo = await fetcher.get('/shards/employer/vacancies/upgraded_vacancy_info', {
            params: { ...params, employerManagerId },
        });
    } catch (err) {
        defaultRequestErrorHandler(err, addNotification);
        return;
    }

    dispatch([
        vacancyFeaturesAction(upgradedInfo.vacancyFeatures),
        vacancyUpdateInitialBodyAction(upgradedInfo.vacancyCreateInitialBody),
    ]);
};
